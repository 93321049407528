@import "includes/_variables";
@import "includes/_fontsize";
@import "includes/_mediaqueries";
@import "includes/_layout";

main {
  margin-top: 150px;

  @include sp-layout() {
    margin-top: 93px;
  }
}

.l-header.is-top .l-global-nav-link {
  @include pc-layout() {
    color: #fff;
    transition: 0.3s;
  }
}

.l-header.is-top {
  &::before {
    opacity: 0;
    background-image: initial;
  }
}

.l-header {
  width: 100%;
  // height: 150px;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: 0.3s;

  @include pc-layout() {
    padding: 65px 5.5% 18px;
  }

  @include sp-layout() {
    height: 93px;
    padding: 20px 0 0 7%;
  }

  &.is-scrolled {
    @include pc-layout() {
      padding: 20px 5.5% 10px;
    }
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    transition: 0.3s;
    opacity: 1;
  }

  &-inner {
    width: 100%;
    // height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &-logo {
    width: auto;
    height: 67px;

    @include sp-layout() {
      height: 62px;
      padding-top: 10px;
    }

    img {
      width: auto;
      height: 100%;
    }
  }

  .c-button {
    position: relative;
    display: inline-block;
    text-decoration: none;
    appearance: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
    z-index: 10;
  }

  .p-hamburger {
    // position: absolute;
    // top: 0;
    // right: 1.5%;
    width: 95px;
    height: 48px;
    transition: all 0.3s ease-in-out;
    @include pc-layout() {
      display: none;
    }
    @include sp-layout() {
      // position: absolute;
      // top: 0;
      // right: 1.5%;
      display: block;
    }
    @include sp-layout() {
      right: 2%;
    }
  }

  .p-hamburger_line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 5px;
    margin: auto;
    width: 53px;
    height: 2px;
    background-color: #323232;
    transition: inherit;
  }
  .p-hamburger_line::before,
  .p-hamburger_line::after {
    position: absolute;
    display: block;
    // width: 100%;
    height: 100%;
    right: 0;
    background-color: inherit;
    content: "";
    transition: inherit;
  }
  .p-hamburger_line::before {
    width: 25px;
    top: -8px;
    left: 0;
  }
  .p-hamburger_line::after {
    width: 25px;
    top: 8px;
  }

  .p-hamburger[aria-expanded="true"] .p-hamburger_line {
    left: 0;
    background-color: transparent;
  }

  .p-hamburger[aria-expanded="true"] .p-hamburger_line::before,
  .p-hamburger[aria-expanded="true"] .p-hamburger_line::after {
    top: 0;
    background-color: $color-light;
  }

  .p-hamburger[aria-expanded="true"] .p-hamburger_line::before {
    width: 40px;
    transform: rotate(45deg);
    left: initial;
  }

  .p-hamburger[aria-expanded="true"] .p-hamburger_line::after {
    width: 40px;
    transform: rotate(-45deg);
  }

  .u-visuallyHidden {
    position: fixed;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
  }

  .js-nav-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    width: 100%;
    height: 120%;
    background-color: $color-default;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.52, 0, 0.5, 1.8);
    @include pc-layout() {
      display: none;
    }
    @include sp-layout() {
      display: block;
    }
  }
  .is-drawerActive .js-nav-cover {
    // opacity: 1;
  }

  .l-global_nav {
    @include pc-layout() {
      width: 67%;
    }

    @include sp-layout() {
      padding: 135px 13% 60px;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      overflow: scroll;
      // visibility: hidden;
      width: 100%;
      // height: 120%;
      background: transparent;
      // background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1)), url(../image/washi_light.jpg);
      // background-position: center;
      // background-repeat: no-repeat;
      // background-size: cover;
      // 適宜背景色を変更する
      // background-color: $color-default;
      // opacity: 0;
      transition: all 0.5s cubic-bezier(0.52, 0, 0.5, 1.8);
      z-index: 9;
    }
  }
  .is-hidden {
    @include pc-layout() {
    }
    @include sp-layout() {
      visibility: hidden;
    }
  }

  .is-drawerActive .l-global_nav {
    visibility: visible;
    border-radius: 0;
    overflow-y: scroll;
    // opacity: 1;
  }

  .l-container {
    @include pc-layout() {
      display: flex;
      justify-content: space-between;
    }

    // @include sp-layout() {
    //     position: absolute;
    //     top: 35%;
    //     left: 50%;
    //     transform: translate(-50%, -50%);
    // }
  }

  .l-global-nav-link {
    font-family: $font-cormorant;
    // color: #fff;
    color: #323232;
    letter-spacing: 0.3em;

    @include sp-layout() {
      font-size: 36px;
    }
  }

  .l-global-nav-primary {
    @include pc-layout() {
      display: flex;
      align-items: center;
    }

    .l-global_nav_item {
      list-style: none;
      @include sp-layout() {
        opacity: 0;
      }
      &:not(:last-child) {
        @include pc-layout() {
          margin-right: 5.5%;
        }
        // @include sp-layout() {
        //     margin-bottom: 10px;
        // }
      }
      &:not(:first-child) {
        @include sp-layout() {
          margin-top: 10px;
        }
      }
    }

    .l-global_nav_item-secondary {
      margin-left: 10%;
    }

    .l-global-nav-link {
      text-align: center;
      position: relative;
      display: block;
      @include sp-layout() {
        color: $color-light;
        text-align: left;
        letter-spacing: normal;
      }
    }

    .l-global-nav-link-secondary {
      @include sp-layout() {
        font-family: $font-cormorant;
        font-size: 26px;
        color: $color-light;
        text-align: left;
        letter-spacing: normal;
      }
    }

    .l-global-nav-link::after {
      position: absolute;
      bottom: -10%;
      left: 25%;
      content: "";
      width: 50%;
      height: 1px;
      background: rgba($color-default, 0.7);
      transform: scale(0, 1);
      transform-origin: right top;
      transition: transform 0.4s;

      @include sp-layout() {
        display: none;
      }
    }
    .l-global-nav-link:hover::after {
      transform-origin: left top;
      transform: scale(1, 1);
    }
  }

  .l-global-nav-secondary {
    display: flex;

    @include pc-layout() {
      width: 13%;
    }

    .item {
      @include pc-layout() {
        margin-right: 13%;
      }

      &:not(:first-child) {
        @include sp-layout() {
          margin-left: 13%;
        }
      }
    }

    .l-global-nav-link {
      @include sp-layout() {
        display: block;
      }
    }

    @include sp-layout() {
      margin-top: 25px;
    }

    .img-insta {
      width: 26px;
      height: 26px;

      @include pc-layout() {
        margin-right: 13%;
      }

      @include sp-layout() {
        width: 39px;
        height: 39px;
      }
    }

    .img-cart {
      width: 30px;
      height: 27px;
    }

    .img-facebook {
      width: 18px;
      height: 36px;
    }
  }

  .link-article {
    max-width: 279px;
    width: 100%;
    margin: 0 auto 35px;
    padding: 18px 0;
    font-family: $font-kozuka;
    font-size: 15px;
    color: #fff;
    text-align: center;
    border: 1px solid #fff;
    display: block;
    letter-spacing: 0.2em;
  }

  .link-wrap {
    margin-top: 60px;

    .link {
      max-width: 279px;
      width: 100%;
      margin: 0 auto;
      color: #fff;
      display: block;
      position: relative;

      &::before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 48%;
        right: 12%;
        vertical-align: middle;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotate(45deg) translateY(-50%);
      }
    }

    .link-youtube {
      margin-bottom: 15px;
      font-family: futura-pt, sans-serif;
      font-weight: 300;
      font-size: 27px;
      text-align: center;
      border: 1px solid #fff;
      padding: 16px 0px;
      letter-spacing: 0.2em;
    }

    .link-shop {
      margin-bottom: 20px;
      font-family: futura-pt, sans-serif;
      font-weight: 300;
      font-size: 23px;
      text-align: center;
      border: 1px solid #fff;
      padding: 22px 0.5em 20px 0;
      letter-spacing: 0.2em;
    }

    .link-reserve {
      margin-bottom: 20px;
      padding: 27px 0;
      font-family: $font-yumincho;
      color: #fff;
      background-color: #ff5080;
      text-align: center;
      letter-spacing: 0.12em;
      position: relative;
    }
  }
}

.l-footer {
  // margin-bottom: 5px;
  padding-top: 80px;
  padding-bottom: 120px;
  color: #fff;
  background-color: #323232;
  position: relative;

  .back-to-top {
    font-family: $font-cormorant;
    font-size: 20px;
    color: #fff;
    transform: rotate(-90deg);
    position: absolute;
    top: 140px;
    right: 1.5%;

    @include sp-layout() {
      font-size: 18px;
      top: 120px;
      right: -6.5%;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -70px;
      transform: translateY(-50%);
      background-color: #fff;
    }

    &::before {
      width: 65px;
      height: 1px;
    }

    &::after {
      width: 10px;
      height: 1px;
      transform: translateY(-3px) rotate(30deg);
    }
  }

  .inner {
    max-width: 960px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include sp-layout() {
      width: 92%;
      margin: 0 auto;
    }
  }

  .wrap {
    .concept {
      font-family: $font-cormorant;
      font-size: 52px;
      line-height: 1.2;

      @include sp-layout() {
        margin-bottom: 5px;
        font-size: 41px;
      }
    }

    .text {
      margin-bottom: 50px;
      font-family: $font-yumincho;
      font-size: 20px;
      letter-spacing: 0.12em;

      @include sp-layout() {
        margin-bottom: 55px;
        font-size: 16px;
      }
    }

    .logo-wrap {
      display: flex;
      align-items: center;

      @include sp-layout() {
        margin-bottom: 55px;
      }

      & img {
        margin-right: 6%;
      }

      & .img-01 {
        width: auto;
        height: 37px;

        @include sp-layout() {
          height: 27px;
        }
      }

      & .img-02 {
        width: auto;
        height: 28px;

        @include sp-layout() {
          height: 21px;
        }
      }

      & .img-03 {
        width: auto;
        height: 35px;

        @include sp-layout() {
          height: 26px;
        }
      }
    }
  }

  .footer-nav {
    width: 55%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include sp-layout() {
      width: 100%;
    }

    .list {
      display: flex;

      @include sp-layout() {
        flex-wrap: wrap;
      }

      &.list-02,
      &.list-03 {
        align-items: center;
      }

      &:nth-child(1) {
        width: 100%;
      }

      .item {
        width: 33.3333%;

        @include sp-layout() {
          width: 100%;
        }

        &:nth-child(2) {
          @include sp-layout() {
            margin-bottom: 30px;
          }
        }

        &:nth-child(3) {
          @include sp-layout() {
            margin-bottom: 25px;
          }
        }
      }

      .sns-share {
        @include sp-layout() {
          display: flex;
        }

        li {
          @include sp-layout() {
            margin-right: 3%;
          }
        }
      }

      li a {
        font-family: $font-yumincho;
        font-size: 12px;
        color: #fff;

        &.insta {
          display: flex;
          align-items: center;

          img {
            width: 10px;
            height: 10px;
            margin-left: 7px;

            @include sp-layout() {
              margin-left: 2px;
            }
          }
        }
      }

      .item ul li {
        margin-bottom: 5px;
      }

      .item ul li,
      .item ul li a {
        font-family: $font-cormorant;
        font-size: 18px;
        letter-spacing: 0.3em;
        color: #fff;
      }

      .item ul li ul li {
        padding-left: 0.8em;
        position: relative;

        &::before {
          content: "";
          width: 4px;
          height: 1px;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: #fff;
        }
      }

      .item ul li ul li a {
        font-family: $font-yumincho;
        font-size: 12px;
      }

      .button-shop {
        max-width: 310px;
        width: 100%;
        height: 72px;
        margin: 0 auto;
        border: 1px solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &::before {
          width: 20px;
          height: 19px;
          left: 5.5%;
          background: center / contain no-repeat
            url(../image/common/icon_cart_light.svg);
        }

        &::after {
          width: 8px;
          height: 16px;
          right: 6%;
          background: center / contain no-repeat
            url(../image/common/icon_arrow_right_light.svg);
        }

        .primary {
          font-family: $font-cormorant;
          font-size: 22px;
          text-transform: uppercase;
          letter-spacing: 0.16em;
          line-height: 1;
        }

        .secondary {
          font-size: 10px;
          letter-spacing: 0.2em;
        }
      }
    }

    .list-02 {
      width: 60%;

      @include sp-layout() {
        width: 100%;
        margin-bottom: 60px;
      }

      li {
        margin-right: 8%;

        a {
          letter-spacing: 0.3em;
        }
      }
    }

    .list-03 {
      @include sp-layout() {
        width: 100%;
      }

      li {
        margin-right: 20px;

        @include sp-layout() {
          width: 100%;
          margin-right: 0;
        }
      }

      .img-insta {
        width: 27px;
        height: 27px;
      }

      .img-cart {
        width: 30px;
        height: 27px;
      }
    }
  }

  .copyright {
    width: 100%;
    margin-top: 100px;
    font-family: $font-cormorant;
    font-size: 10px;
    letter-spacing: 0.1em;
    text-align: center;

    @include sp-layout() {
      margin-top: 50px;
    }
  }
}

.is-drawerActive {
  overflow-y: hidden;
}

.c-title-primary {
  margin-bottom: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @include sp-layout() {
    margin-bottom: 60px;
  }

  .primary,
  .secondary {
    display: inline-block;
  }

  .primary {
    font-family: $font-cormorant;
    font-size: 50px;
    text-transform: uppercase;

    @include sp-layout() {
      font-size: 34px;
    }
  }

  .secondary {
    margin-top: -15px;
    font-family: $font-yumincho;
    font-size: 14px;
    letter-spacing: 0.36em;

    @include sp-layout() {
      margin-top: -5px;
    }
  }
}

.c-mv {
  position: relative;

  .title {
    color: $color-light;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include sp-layout() {
      width: 100%;
      top: 47%;
    }

    .primary {
      font-family: $font-cormorant;
      font-size: 77px;
      text-transform: uppercase;

      @include sp-layout() {
        font-size: 52px;
      }
    }

    .secondary {
      margin-top: -20px;
      font-family: $font-yumincho;
      font-size: 20px;
      letter-spacing: 0.36em;

      @include sp-layout() {
        margin-top: -13px;
        font-size: 14px;
      }
    }
  }
}

.c-title-third {
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  @include sp-layout() {
    margin-bottom: 55px;
  }

  &::before {
    content: "";
    width: 236px;
    height: 1px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: $color-default;

    @include sp-layout() {
      bottom: -15px;
    }
  }

  .primary {
    font-family: $font-cormorant;
    font-size: 38px;
    text-transform: uppercase;

    @include sp-layout() {
      font-size: 30px;
    }
  }

  .secondary {
    margin-top: -7px;
    font-family: $font-yumincho;
    font-size: 14px;
    letter-spacing: 0.2em;

    @include sp-layout() {
      font-size: 12px;
    }
  }
}

.c-link-primary {
  width: 200px;
  padding: 15px 0;
  font-family: $font-yumincho;
  font-size: 18px;
  letter-spacing: 0.16em;
  text-align: center;
  color: #353535;
  background-color: #fff;
  border: 1px solid #353535;
  display: block;
  position: relative;
  transition: 0.3s;

  @include sp-layout() {
    width: 131px;
    margin-right: 5%;
    padding: 8px 0;
    font-size: 14px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    right: -7px;
    bottom: -6px;
    background-color: #353535;
    transition: 0.3s;

    @include sp-layout() {
      bottom: -7px;
    }
  }

  &::before {
    width: 1px;
    height: 38px;

    @include sp-layout() {
      height: 23px;
    }
  }

  &::after {
    width: 52px;
    height: 1px;

    @include sp-layout() {
      width: 127px;
    }
  }

  span {
    display: block;
    position: relative;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: 48%;
      right: 12%;
      vertical-align: middle;
      border-top: 1px solid #383838;
      border-right: 1px solid #383838;
      transform: rotate(45deg) translateY(-50%);

      @include sp-layout() {
        width: 8px;
        height: 8px;
      }
    }
  }

  &:hover {
    color: #fff;
    background-color: #353535;
  }

  &:hover span::before {
    border-color: #fff;
  }
}

.cta-menu {
  margin-bottom: 130px;

  @include sp-layout() {
    margin-bottom: 60px;
  }

  .title {
    margin-bottom: 20px;
    font-family: $font-cormorant;
    font-size: 64px;
    text-transform: uppercase;
    text-align: center;

    @include sp-layout() {
      margin-bottom: 30px;
      font-size: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .primary,
    .secondary {
      display: inline-block;
    }

    .primary {
      @include sp-layout() {
        margin-bottom: 15px;
        line-height: 0.8;
      }
    }

    .secondary {
      font-family: $font-yumincho;
      font-size: 14px;
      letter-spacing: 0.36em;
    }
  }

  .link-wrap {
    position: relative;

    .link {
      width: 322px;
      padding: 17px 0;
      font-family: $font-yumincho;
      font-size: 22px;
      text-align: center;
      color: $color-light;
      background-color: rgba($color-light, 0.3);
      letter-spacing: 0.2em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include sp-layout() {
        width: 223px;
        padding: 12px 0;
        font-size: 14px;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: -6px;
        right: -6px;
        background-color: rgba($color-light, 0.3);
      }

      &::before {
        width: 60px;
        height: 1px;

        @include sp-layout() {
          width: 40px;
        }
      }

      &::after {
        width: 1px;
        height: 44px;

        @include sp-layout() {
          height: 29px;
        }
      }

      .link-inner {
        display: block;
        position: relative;

        &::before {
          content: "";
          width: 12px;
          height: 12px;
          position: absolute;
          top: 50%;
          right: 35px;
          vertical-align: middle;
          border-top: 1px solid $color-light;
          border-right: 1px solid $color-light;
          transform: rotate(45deg) translateY(-50%);

          @include sp-layout() {
            right: 25px;
            width: 8px;
            height: 8px;
          }
        }
      }
    }
  }
}

.cta-info {
  padding: 130px 0 200px;

  @include sp-layout() {
    padding: 50px 0 75px;
  }

  .title {
    margin-bottom: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include sp-layout() {
      margin-bottom: 50px;
    }

    .primary {
      margin-bottom: 23px;
      font-family: $font-cormorant;
      font-size: 120px;
      text-transform: uppercase;
      text-align: center;
      line-height: 0.85;

      @include sp-layout() {
        margin-bottom: 8px;
        font-size: 44px;
        line-height: 1;
      }
    }

    .secondary {
      font-family: $font-yumincho;
      font-size: 18px;
      letter-spacing: 0.32em;

      @include sp-layout() {
        font-size: 14px;
      }
    }
  }

  .l-2col-wrap {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .list {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
  }

  .item:not(:first-child) {
    margin-top: 150px;

    @include sp-layout() {
      margin-top: 95px;
    }
  }

  .item-primary {
    width: 42%;
    padding-top: 5px;

    @include sp-layout() {
      width: 93%;
      margin-bottom: 45px;
      padding-top: 0;
    }
  }

  .item-secondary {
    width: 41%;
    margin-left: 5.5%;

    @include sp-layout() {
      width: 100%;
      margin-left: 0;
      padding: 0 7%;
    }
  }

  .lead {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .logo-01 {
      width: auto;
      height: 75px;

      @include sp-layout() {
        height: 54px;
      }
    }

    .logo-02 {
      width: auto;
      height: 50px;

      @include sp-layout() {
        height: 27px;
      }
    }

    .logo-03 {
      width: auto;
      height: 61px;

      @include sp-layout() {
        height: 36px;
      }
    }

    p {
      margin-left: 5.5%;
      font-family: $font-yumincho;
      font-size: 18px;
      letter-spacing: 0.3em;

      @include sp-layout() {
        font-size: 13px;
      }
    }

    .link-insta {
      width: 37px;
      height: 37px;
      margin-left: 2%;

      @include sp-layout() {
        width: 27px;
        height: 27px;
      }
    }
  }

  .table {
    width: 100%;
    margin-bottom: 30px;

    @include sp-layout() {
      margin-bottom: 20px;
    }

    .heading,
    .data {
      padding: 11px 0;
      font-size: 13px;
      color: #666666;

      @include sp-layout() {
        padding: 9px 0;
        font-size: 10px;
      }
    }

    .heading {
      width: 17%;
      letter-spacing: 0.2em;

      @include sp-layout() {
        width: 21%;
        padding-left: 2.5%;
      }
    }

    .data {
      width: 83%;
      letter-spacing: 0.2em;

      @include sp-layout() {
        width: 79%;
      }
    }

    .map {
      margin-top: 5px;
      padding: 0 7px;
      font-family: $font-kozuka;
      font-size: 8px;
      letter-spacing: normal;
      color: #323232;
      border: 1px solid #323232;
      border-radius: 3px;
      display: inline-block;
    }

    .tel {
      color: #666666;
      border-bottom: 1px solid #666666;
      display: inline-block;
    }
  }

  .c-link-primary {
    margin: 0 6px 30px auto;
    padding: 10px 0;

    @include sp-layout() {
      width: 200px;
      margin-bottom: 25px;
      padding: 11px 0;
      font-size: 16px;
    }

    &::before {
      @include sp-layout() {
        height: 38px;
      }
    }

    &::after {
      @include sp-layout() {
        width: 52px;
      }
    }
  }

  .link-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .link {
      width: 49.5%;
      padding: 19px 0;
      font-family: $font-yumincho;
      font-weight: 600;
      text-align: center;
      letter-spacing: 0.05em;
      color: $color-default;
      display: block;
      position: relative;
      transition: 0.3s;

      @include sp-layout() {
        width: 100%;
        padding: 23px 0;
        font-size: 21px;
      }

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        position: absolute;
        top: 50%;
        right: 12%;
        vertical-align: middle;
        border-top: 1px solid $color-default;
        border-right: 1px solid $color-default;
        transform: rotate(45deg) translateY(-50%);

        @include sp-layout() {
          width: 12px;
          height: 12px;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      &:not(:first-child) {
        @include sp-layout() {
          margin-top: 10px;
        }
      }
    }

    .link-primary,
    .link-secondary {
      &::before {
        border-top: 1px solid $color-light;
        border-right: 1px solid $color-light;
      }
    }

    .link-primary {
      color: $color-light;
      background-color: $color-pink;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: -5px;
        left: 0;
        background-color: $color-pink-dark;

        @include sp-layout() {
          height: 6px;
          bottom: -6px;
        }
      }

      .link-inner {
        position: relative;

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: -3em;
          transform: translateY(-50%);
          background: center / contain no-repeat
            url(../image/common/icon_tel.svg);
        }
      }
    }

    .link-secondary {
      color: $color-light;
      background-color: $color-green;
      position: relative;

      &::after {
        content: "";
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: -5px;
        left: 0;
        background-color: $color-green-dark;

        @include sp-layout() {
          height: 6px;
          bottom: -6px;
        }
      }

      .link-inner {
        position: relative;

        &::before {
          content: "";
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          left: -2.8em;
          transform: translateY(-50%);
          background: center / contain no-repeat
            url(../image/common/icon_coupon.svg);
        }
      }
    }
  }
}

.p-pagenav {
  margin-top: 125px;
  margin-bottom: 95px;
  display: flex;
  justify-content: center;

  @include pc-layout() {
    width: 100%;
    order: 2;
  }

  @include sp-layout() {
    margin-top: 65px;
    margin-bottom: 80px;
  }
}

.p-pagenav .page-numbers {
  width: 55px;
  height: 55px;
  font-family: $font-cormorant;
  font-size: 45px;
  color: #262626;
  background-color: #d1d1d1;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  // line-height: 55px;
  transition: 0.3s;

  @include sp-layout() {
    width: 40px;
    height: 40px;
    font-size: 33px;
  }
}

.p-pagenav .page-numbers:hover {
  background-color: #d1d1d1;
}

.p-pagenav .page-numbers:not(:first-child) {
  margin-left: 5px;
}

.p-pagenav .page-numbers.current {
  color: #fff;
  background-color: #262626;
}

.p-pagenav .page-numbers.dots {
  border: none;
}

.p-pagenav .page-numbers.prev,
.p-pagenav .page-numbers.next {
  background-color: #262626;
  position: relative;
}

.p-pagenav .page-numbers.prev::before,
.p-pagenav .page-numbers.next::before {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;

  @include sp-layout() {
    width: 10px;
    height: 10px;
  }
}

.p-pagenav .page-numbers.prev::before {
  top: 23%;
  left: 43%;
  // background-color: #fff;
  border-top: 1px solid $color-light;
  border-right: 1px solid $color-light;
  transform-origin: center;
  transform: rotate(-135deg) translate(-50%, -50%);
  // transform: rotate(-45deg);

  @include sp-layout() {
    top: 18%;
  }
}

.p-pagenav .page-numbers.next::before {
  top: 57%;
  left: 35%;
  // background-color: #fff;
  border-top: 1px solid $color-light;
  border-right: 1px solid $color-light;
  transform-origin: center;
  transform: rotate(45deg) translate(-50%, -50%);
  // transform: rotate(-45deg);

  @include sp-layout() {
    top: 55%;
    left: 33%;
  }
}

.js-scrollFadeAnim,
.js-staggerFadeItem {
  opacity: 0;
}
