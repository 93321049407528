@charset "UTF-8";
.l-content-wrap {
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
  padding-right: 2.4% !important;
  padding-left: 2.4% !important;
}
@media only screen and (max-width: 767px) {
  .l-content-wrap {
    max-width: 100%;
    padding-right: 5% !important;
    padding-left: 5% !important;
    box-sizing: border-box !important;
  }
}

@media only screen and (max-width: 767px) {
  .l-spinner {
    width: 85%;
    margin: 0 auto;
  }
}

.l-content-wrap-default {
  position: relative;
  max-width: 1024px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: content-box !important;
  padding-right: 2.4% !important;
  padding-left: 2.4% !important;
}
@media only screen and (max-width: 767px) {
  .l-content-wrap-default {
    max-width: 100%;
    padding-right: 5% !important;
    padding-left: 5% !important;
    box-sizing: border-box !important;
  }
}

main {
  margin-top: 150px;
}
@media only screen and (max-width: 767px) {
  main {
    margin-top: 93px;
  }
}

@media only screen and (min-width: 768px) {
  .l-header.is-top .l-global-nav-link {
    color: #fff;
    transition: 0.3s;
  }
}

.l-header.is-top::before {
  opacity: 0;
  background-image: initial;
}

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  transition: 0.3s;
}
@media only screen and (min-width: 768px) {
  .l-header {
    padding: 65px 5.5% 18px;
  }
}
@media only screen and (max-width: 767px) {
  .l-header {
    height: 93px;
    padding: 20px 0 0 7%;
  }
}
@media only screen and (min-width: 768px) {
  .l-header.is-scrolled {
    padding: 20px 5.5% 10px;
  }
}
.l-header::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  transition: 0.3s;
  opacity: 1;
}
.l-header-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.l-header-logo {
  width: auto;
  height: 67px;
}
@media only screen and (max-width: 767px) {
  .l-header-logo {
    height: 62px;
    padding-top: 10px;
  }
}
.l-header-logo img {
  width: auto;
  height: 100%;
}
.l-header .c-button {
  position: relative;
  display: inline-block;
  text-decoration: none;
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
}
.l-header .p-hamburger {
  width: 95px;
  height: 48px;
  transition: all 0.3s ease-in-out;
}
@media only screen and (min-width: 768px) {
  .l-header .p-hamburger {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .p-hamburger {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .p-hamburger {
    right: 2%;
  }
}
.l-header .p-hamburger_line {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 5px;
  margin: auto;
  width: 53px;
  height: 2px;
  background-color: #323232;
  transition: inherit;
}
.l-header .p-hamburger_line::before,
.l-header .p-hamburger_line::after {
  position: absolute;
  display: block;
  height: 100%;
  right: 0;
  background-color: inherit;
  content: "";
  transition: inherit;
}
.l-header .p-hamburger_line::before {
  width: 25px;
  top: -8px;
  left: 0;
}
.l-header .p-hamburger_line::after {
  width: 25px;
  top: 8px;
}
.l-header .p-hamburger[aria-expanded=true] .p-hamburger_line {
  left: 0;
  background-color: transparent;
}
.l-header .p-hamburger[aria-expanded=true] .p-hamburger_line::before,
.l-header .p-hamburger[aria-expanded=true] .p-hamburger_line::after {
  top: 0;
  background-color: #fff;
}
.l-header .p-hamburger[aria-expanded=true] .p-hamburger_line::before {
  width: 40px;
  transform: rotate(45deg);
  left: initial;
}
.l-header .p-hamburger[aria-expanded=true] .p-hamburger_line::after {
  width: 40px;
  transform: rotate(-45deg);
}
.l-header .u-visuallyHidden {
  position: fixed;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}
.l-header .js-nav-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 120%;
  background-color: #383838;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.52, 0, 0.5, 1.8);
}
@media only screen and (min-width: 768px) {
  .l-header .js-nav-cover {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .js-nav-cover {
    display: block;
  }
}
@media only screen and (min-width: 768px) {
  .l-header .l-global_nav {
    width: 67%;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .l-global_nav {
    padding: 135px 13% 60px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    overflow: scroll;
    width: 100%;
    background: transparent;
    transition: all 0.5s cubic-bezier(0.52, 0, 0.5, 1.8);
    z-index: 9;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .is-hidden {
    visibility: hidden;
  }
}
.l-header .is-drawerActive .l-global_nav {
  visibility: visible;
  border-radius: 0;
  overflow-y: scroll;
}
@media only screen and (min-width: 768px) {
  .l-header .l-container {
    display: flex;
    justify-content: space-between;
  }
}
.l-header .l-global-nav-link {
  font-family: "Cormorant", serif;
  color: #323232;
  letter-spacing: 0.3em;
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-link {
    font-size: 36px;
  }
}
@media only screen and (min-width: 768px) {
  .l-header .l-global-nav-primary {
    display: flex;
    align-items: center;
  }
}
.l-header .l-global-nav-primary .l-global_nav_item {
  list-style: none;
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-primary .l-global_nav_item {
    opacity: 0;
  }
}
@media only screen and (min-width: 768px) {
  .l-header .l-global-nav-primary .l-global_nav_item:not(:last-child) {
    margin-right: 5.5%;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-primary .l-global_nav_item:not(:first-child) {
    margin-top: 10px;
  }
}
.l-header .l-global-nav-primary .l-global_nav_item-secondary {
  margin-left: 10%;
}
.l-header .l-global-nav-primary .l-global-nav-link {
  text-align: center;
  position: relative;
  display: block;
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-primary .l-global-nav-link {
    color: #fff;
    text-align: left;
    letter-spacing: normal;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-primary .l-global-nav-link-secondary {
    font-family: "Cormorant", serif;
    font-size: 26px;
    color: #fff;
    text-align: left;
    letter-spacing: normal;
  }
}
.l-header .l-global-nav-primary .l-global-nav-link::after {
  position: absolute;
  bottom: -10%;
  left: 25%;
  content: "";
  width: 50%;
  height: 1px;
  background: rgba(56, 56, 56, 0.7);
  transform: scale(0, 1);
  transform-origin: right top;
  transition: transform 0.4s;
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-primary .l-global-nav-link::after {
    display: none;
  }
}
.l-header .l-global-nav-primary .l-global-nav-link:hover::after {
  transform-origin: left top;
  transform: scale(1, 1);
}
.l-header .l-global-nav-secondary {
  display: flex;
}
@media only screen and (min-width: 768px) {
  .l-header .l-global-nav-secondary {
    width: 13%;
  }
}
@media only screen and (min-width: 768px) {
  .l-header .l-global-nav-secondary .item {
    margin-right: 13%;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-secondary .item:not(:first-child) {
    margin-left: 13%;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-secondary .l-global-nav-link {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-secondary {
    margin-top: 25px;
  }
}
.l-header .l-global-nav-secondary .img-insta {
  width: 26px;
  height: 26px;
}
@media only screen and (min-width: 768px) {
  .l-header .l-global-nav-secondary .img-insta {
    margin-right: 13%;
  }
}
@media only screen and (max-width: 767px) {
  .l-header .l-global-nav-secondary .img-insta {
    width: 39px;
    height: 39px;
  }
}
.l-header .l-global-nav-secondary .img-cart {
  width: 30px;
  height: 27px;
}
.l-header .l-global-nav-secondary .img-facebook {
  width: 18px;
  height: 36px;
}
.l-header .link-article {
  max-width: 279px;
  width: 100%;
  margin: 0 auto 35px;
  padding: 18px 0;
  font-family: "小塚ゴシック Pro", "Kozuka Gothic Pro", sans-serif;
  font-size: 15px;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  display: block;
  letter-spacing: 0.2em;
}
.l-header .link-wrap {
  margin-top: 60px;
}
.l-header .link-wrap .link {
  max-width: 279px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  display: block;
  position: relative;
}
.l-header .link-wrap .link::before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  top: 48%;
  right: 12%;
  vertical-align: middle;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg) translateY(-50%);
}
.l-header .link-wrap .link-youtube {
  margin-bottom: 15px;
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  font-size: 27px;
  text-align: center;
  border: 1px solid #fff;
  padding: 16px 0px;
  letter-spacing: 0.2em;
}
.l-header .link-wrap .link-shop {
  margin-bottom: 20px;
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  font-size: 23px;
  text-align: center;
  border: 1px solid #fff;
  padding: 22px 0.5em 20px 0;
  letter-spacing: 0.2em;
}
.l-header .link-wrap .link-reserve {
  margin-bottom: 20px;
  padding: 27px 0;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  color: #fff;
  background-color: #ff5080;
  text-align: center;
  letter-spacing: 0.12em;
  position: relative;
}

.l-footer {
  padding-top: 80px;
  padding-bottom: 120px;
  color: #fff;
  background-color: #323232;
  position: relative;
}
.l-footer .back-to-top {
  font-family: "Cormorant", serif;
  font-size: 20px;
  color: #fff;
  transform: rotate(-90deg);
  position: absolute;
  top: 140px;
  right: 1.5%;
}
@media only screen and (max-width: 767px) {
  .l-footer .back-to-top {
    font-size: 18px;
    top: 120px;
    right: -6.5%;
  }
}
.l-footer .back-to-top::before, .l-footer .back-to-top::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -70px;
  transform: translateY(-50%);
  background-color: #fff;
}
.l-footer .back-to-top::before {
  width: 65px;
  height: 1px;
}
.l-footer .back-to-top::after {
  width: 10px;
  height: 1px;
  transform: translateY(-3px) rotate(30deg);
}
.l-footer .inner {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .l-footer .inner {
    width: 92%;
    margin: 0 auto;
  }
}
.l-footer .wrap .concept {
  font-family: "Cormorant", serif;
  font-size: 52px;
  line-height: 1.2;
}
@media only screen and (max-width: 767px) {
  .l-footer .wrap .concept {
    margin-bottom: 5px;
    font-size: 41px;
  }
}
.l-footer .wrap .text {
  margin-bottom: 50px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 20px;
  letter-spacing: 0.12em;
}
@media only screen and (max-width: 767px) {
  .l-footer .wrap .text {
    margin-bottom: 55px;
    font-size: 16px;
  }
}
.l-footer .wrap .logo-wrap {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .l-footer .wrap .logo-wrap {
    margin-bottom: 55px;
  }
}
.l-footer .wrap .logo-wrap img {
  margin-right: 6%;
}
.l-footer .wrap .logo-wrap .img-01 {
  width: auto;
  height: 37px;
}
@media only screen and (max-width: 767px) {
  .l-footer .wrap .logo-wrap .img-01 {
    height: 27px;
  }
}
.l-footer .wrap .logo-wrap .img-02 {
  width: auto;
  height: 28px;
}
@media only screen and (max-width: 767px) {
  .l-footer .wrap .logo-wrap .img-02 {
    height: 21px;
  }
}
.l-footer .wrap .logo-wrap .img-03 {
  width: auto;
  height: 35px;
}
@media only screen and (max-width: 767px) {
  .l-footer .wrap .logo-wrap .img-03 {
    height: 26px;
  }
}
.l-footer .footer-nav {
  width: 55%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav {
    width: 100%;
  }
}
.l-footer .footer-nav .list {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list {
    flex-wrap: wrap;
  }
}
.l-footer .footer-nav .list.list-02, .l-footer .footer-nav .list.list-03 {
  align-items: center;
}
.l-footer .footer-nav .list:nth-child(1) {
  width: 100%;
}
.l-footer .footer-nav .list .item {
  width: 33.3333%;
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list .item {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list .item:nth-child(2) {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list .item:nth-child(3) {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list .sns-share {
    display: flex;
  }
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list .sns-share li {
    margin-right: 3%;
  }
}
.l-footer .footer-nav .list li a {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 12px;
  color: #fff;
}
.l-footer .footer-nav .list li a.insta {
  display: flex;
  align-items: center;
}
.l-footer .footer-nav .list li a.insta img {
  width: 10px;
  height: 10px;
  margin-left: 7px;
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list li a.insta img {
    margin-left: 2px;
  }
}
.l-footer .footer-nav .list .item ul li {
  margin-bottom: 5px;
}
.l-footer .footer-nav .list .item ul li,
.l-footer .footer-nav .list .item ul li a {
  font-family: "Cormorant", serif;
  font-size: 18px;
  letter-spacing: 0.3em;
  color: #fff;
}
.l-footer .footer-nav .list .item ul li ul li {
  padding-left: 0.8em;
  position: relative;
}
.l-footer .footer-nav .list .item ul li ul li::before {
  content: "";
  width: 4px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  background-color: #fff;
}
.l-footer .footer-nav .list .item ul li ul li a {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 12px;
}
.l-footer .footer-nav .list .button-shop {
  max-width: 310px;
  width: 100%;
  height: 72px;
  margin: 0 auto;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.l-footer .footer-nav .list .button-shop::before, .l-footer .footer-nav .list .button-shop::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.l-footer .footer-nav .list .button-shop::before {
  width: 20px;
  height: 19px;
  left: 5.5%;
  background: center/contain no-repeat url(../image/common/icon_cart_light.svg);
}
.l-footer .footer-nav .list .button-shop::after {
  width: 8px;
  height: 16px;
  right: 6%;
  background: center/contain no-repeat url(../image/common/icon_arrow_right_light.svg);
}
.l-footer .footer-nav .list .button-shop .primary {
  font-family: "Cormorant", serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  line-height: 1;
}
.l-footer .footer-nav .list .button-shop .secondary {
  font-size: 10px;
  letter-spacing: 0.2em;
}
.l-footer .footer-nav .list-02 {
  width: 60%;
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list-02 {
    width: 100%;
    margin-bottom: 60px;
  }
}
.l-footer .footer-nav .list-02 li {
  margin-right: 8%;
}
.l-footer .footer-nav .list-02 li a {
  letter-spacing: 0.3em;
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list-03 {
    width: 100%;
  }
}
.l-footer .footer-nav .list-03 li {
  margin-right: 20px;
}
@media only screen and (max-width: 767px) {
  .l-footer .footer-nav .list-03 li {
    width: 100%;
    margin-right: 0;
  }
}
.l-footer .footer-nav .list-03 .img-insta {
  width: 27px;
  height: 27px;
}
.l-footer .footer-nav .list-03 .img-cart {
  width: 30px;
  height: 27px;
}
.l-footer .copyright {
  width: 100%;
  margin-top: 100px;
  font-family: "Cormorant", serif;
  font-size: 10px;
  letter-spacing: 0.1em;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .l-footer .copyright {
    margin-top: 50px;
  }
}

.is-drawerActive {
  overflow-y: hidden;
}

.c-title-primary {
  margin-bottom: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .c-title-primary {
    margin-bottom: 60px;
  }
}
.c-title-primary .primary,
.c-title-primary .secondary {
  display: inline-block;
}
.c-title-primary .primary {
  font-family: "Cormorant", serif;
  font-size: 50px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .c-title-primary .primary {
    font-size: 34px;
  }
}
.c-title-primary .secondary {
  margin-top: -15px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 14px;
  letter-spacing: 0.36em;
}
@media only screen and (max-width: 767px) {
  .c-title-primary .secondary {
    margin-top: -5px;
  }
}

.c-mv {
  position: relative;
}
.c-mv .title {
  color: #fff;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .c-mv .title {
    width: 100%;
    top: 47%;
  }
}
.c-mv .title .primary {
  font-family: "Cormorant", serif;
  font-size: 77px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .c-mv .title .primary {
    font-size: 52px;
  }
}
.c-mv .title .secondary {
  margin-top: -20px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 20px;
  letter-spacing: 0.36em;
}
@media only screen and (max-width: 767px) {
  .c-mv .title .secondary {
    margin-top: -13px;
    font-size: 14px;
  }
}

.c-title-third {
  margin-bottom: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .c-title-third {
    margin-bottom: 55px;
  }
}
.c-title-third::before {
  content: "";
  width: 236px;
  height: 1px;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #383838;
}
@media only screen and (max-width: 767px) {
  .c-title-third::before {
    bottom: -15px;
  }
}
.c-title-third .primary {
  font-family: "Cormorant", serif;
  font-size: 38px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .c-title-third .primary {
    font-size: 30px;
  }
}
.c-title-third .secondary {
  margin-top: -7px;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 14px;
  letter-spacing: 0.2em;
}
@media only screen and (max-width: 767px) {
  .c-title-third .secondary {
    font-size: 12px;
  }
}

.c-link-primary {
  width: 200px;
  padding: 15px 0;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 18px;
  letter-spacing: 0.16em;
  text-align: center;
  color: #353535;
  background-color: #fff;
  border: 1px solid #353535;
  display: block;
  position: relative;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .c-link-primary {
    width: 131px;
    margin-right: 5%;
    padding: 8px 0;
    font-size: 14px;
  }
}
.c-link-primary::before, .c-link-primary::after {
  content: "";
  position: absolute;
  right: -7px;
  bottom: -6px;
  background-color: #353535;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .c-link-primary::before, .c-link-primary::after {
    bottom: -7px;
  }
}
.c-link-primary::before {
  width: 1px;
  height: 38px;
}
@media only screen and (max-width: 767px) {
  .c-link-primary::before {
    height: 23px;
  }
}
.c-link-primary::after {
  width: 52px;
  height: 1px;
}
@media only screen and (max-width: 767px) {
  .c-link-primary::after {
    width: 127px;
  }
}
.c-link-primary span {
  display: block;
  position: relative;
}
.c-link-primary span::before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  top: 48%;
  right: 12%;
  vertical-align: middle;
  border-top: 1px solid #383838;
  border-right: 1px solid #383838;
  transform: rotate(45deg) translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .c-link-primary span::before {
    width: 8px;
    height: 8px;
  }
}
.c-link-primary:hover {
  color: #fff;
  background-color: #353535;
}
.c-link-primary:hover span::before {
  border-color: #fff;
}

.cta-menu {
  margin-bottom: 130px;
}
@media only screen and (max-width: 767px) {
  .cta-menu {
    margin-bottom: 60px;
  }
}
.cta-menu .title {
  margin-bottom: 20px;
  font-family: "Cormorant", serif;
  font-size: 64px;
  text-transform: uppercase;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .cta-menu .title {
    margin-bottom: 30px;
    font-size: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.cta-menu .title .primary,
.cta-menu .title .secondary {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .cta-menu .title .primary {
    margin-bottom: 15px;
    line-height: 0.8;
  }
}
.cta-menu .title .secondary {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 14px;
  letter-spacing: 0.36em;
}
.cta-menu .link-wrap {
  position: relative;
}
.cta-menu .link-wrap .link {
  width: 322px;
  padding: 17px 0;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 22px;
  text-align: center;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.3);
  letter-spacing: 0.2em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
  .cta-menu .link-wrap .link {
    width: 223px;
    padding: 12px 0;
    font-size: 14px;
  }
}
.cta-menu .link-wrap .link::before, .cta-menu .link-wrap .link::after {
  content: "";
  position: absolute;
  bottom: -6px;
  right: -6px;
  background-color: rgba(255, 255, 255, 0.3);
}
.cta-menu .link-wrap .link::before {
  width: 60px;
  height: 1px;
}
@media only screen and (max-width: 767px) {
  .cta-menu .link-wrap .link::before {
    width: 40px;
  }
}
.cta-menu .link-wrap .link::after {
  width: 1px;
  height: 44px;
}
@media only screen and (max-width: 767px) {
  .cta-menu .link-wrap .link::after {
    height: 29px;
  }
}
.cta-menu .link-wrap .link .link-inner {
  display: block;
  position: relative;
}
.cta-menu .link-wrap .link .link-inner::before {
  content: "";
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: 35px;
  vertical-align: middle;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg) translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .cta-menu .link-wrap .link .link-inner::before {
    right: 25px;
    width: 8px;
    height: 8px;
  }
}

.cta-info {
  padding: 130px 0 200px;
}
@media only screen and (max-width: 767px) {
  .cta-info {
    padding: 50px 0 75px;
  }
}
.cta-info .title {
  margin-bottom: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 767px) {
  .cta-info .title {
    margin-bottom: 50px;
  }
}
.cta-info .title .primary {
  margin-bottom: 23px;
  font-family: "Cormorant", serif;
  font-size: 120px;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.85;
}
@media only screen and (max-width: 767px) {
  .cta-info .title .primary {
    margin-bottom: 8px;
    font-size: 44px;
    line-height: 1;
  }
}
.cta-info .title .secondary {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 18px;
  letter-spacing: 0.32em;
}
@media only screen and (max-width: 767px) {
  .cta-info .title .secondary {
    font-size: 14px;
  }
}
.cta-info .l-2col-wrap {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.cta-info .list {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
}
.cta-info .item:not(:first-child) {
  margin-top: 150px;
}
@media only screen and (max-width: 767px) {
  .cta-info .item:not(:first-child) {
    margin-top: 95px;
  }
}
.cta-info .item-primary {
  width: 42%;
  padding-top: 5px;
}
@media only screen and (max-width: 767px) {
  .cta-info .item-primary {
    width: 93%;
    margin-bottom: 45px;
    padding-top: 0;
  }
}
.cta-info .item-secondary {
  width: 41%;
  margin-left: 5.5%;
}
@media only screen and (max-width: 767px) {
  .cta-info .item-secondary {
    width: 100%;
    margin-left: 0;
    padding: 0 7%;
  }
}
.cta-info .lead {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cta-info .lead .logo-01 {
  width: auto;
  height: 75px;
}
@media only screen and (max-width: 767px) {
  .cta-info .lead .logo-01 {
    height: 54px;
  }
}
.cta-info .lead .logo-02 {
  width: auto;
  height: 50px;
}
@media only screen and (max-width: 767px) {
  .cta-info .lead .logo-02 {
    height: 27px;
  }
}
.cta-info .lead .logo-03 {
  width: auto;
  height: 61px;
}
@media only screen and (max-width: 767px) {
  .cta-info .lead .logo-03 {
    height: 36px;
  }
}
.cta-info .lead p {
  margin-left: 5.5%;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-size: 18px;
  letter-spacing: 0.3em;
}
@media only screen and (max-width: 767px) {
  .cta-info .lead p {
    font-size: 13px;
  }
}
.cta-info .lead .link-insta {
  width: 37px;
  height: 37px;
  margin-left: 2%;
}
@media only screen and (max-width: 767px) {
  .cta-info .lead .link-insta {
    width: 27px;
    height: 27px;
  }
}
.cta-info .table {
  width: 100%;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .cta-info .table {
    margin-bottom: 20px;
  }
}
.cta-info .table .heading,
.cta-info .table .data {
  padding: 11px 0;
  font-size: 13px;
  color: #666666;
}
@media only screen and (max-width: 767px) {
  .cta-info .table .heading,
.cta-info .table .data {
    padding: 9px 0;
    font-size: 10px;
  }
}
.cta-info .table .heading {
  width: 17%;
  letter-spacing: 0.2em;
}
@media only screen and (max-width: 767px) {
  .cta-info .table .heading {
    width: 21%;
    padding-left: 2.5%;
  }
}
.cta-info .table .data {
  width: 83%;
  letter-spacing: 0.2em;
}
@media only screen and (max-width: 767px) {
  .cta-info .table .data {
    width: 79%;
  }
}
.cta-info .table .map {
  margin-top: 5px;
  padding: 0 7px;
  font-family: "小塚ゴシック Pro", "Kozuka Gothic Pro", sans-serif;
  font-size: 8px;
  letter-spacing: normal;
  color: #323232;
  border: 1px solid #323232;
  border-radius: 3px;
  display: inline-block;
}
.cta-info .table .tel {
  color: #666666;
  border-bottom: 1px solid #666666;
  display: inline-block;
}
.cta-info .c-link-primary {
  margin: 0 6px 30px auto;
  padding: 10px 0;
}
@media only screen and (max-width: 767px) {
  .cta-info .c-link-primary {
    width: 200px;
    margin-bottom: 25px;
    padding: 11px 0;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .cta-info .c-link-primary::before {
    height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .cta-info .c-link-primary::after {
    width: 52px;
  }
}
.cta-info .link-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cta-info .link-wrap .link {
  width: 49.5%;
  padding: 19px 0;
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.05em;
  color: #383838;
  display: block;
  position: relative;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .cta-info .link-wrap .link {
    width: 100%;
    padding: 23px 0;
    font-size: 21px;
  }
}
.cta-info .link-wrap .link::before {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  right: 12%;
  vertical-align: middle;
  border-top: 1px solid #383838;
  border-right: 1px solid #383838;
  transform: rotate(45deg) translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .cta-info .link-wrap .link::before {
    width: 12px;
    height: 12px;
  }
}
.cta-info .link-wrap .link:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 767px) {
  .cta-info .link-wrap .link:not(:first-child) {
    margin-top: 10px;
  }
}
.cta-info .link-wrap .link-primary::before,
.cta-info .link-wrap .link-secondary::before {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
}
.cta-info .link-wrap .link-primary {
  color: #fff;
  background-color: #ff527f;
  position: relative;
}
.cta-info .link-wrap .link-primary::after {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background-color: #ea1b66;
}
@media only screen and (max-width: 767px) {
  .cta-info .link-wrap .link-primary::after {
    height: 6px;
    bottom: -6px;
  }
}
.cta-info .link-wrap .link-primary .link-inner {
  position: relative;
}
.cta-info .link-wrap .link-primary .link-inner::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: -3em;
  transform: translateY(-50%);
  background: center/contain no-repeat url(../image/common/icon_tel.svg);
}
.cta-info .link-wrap .link-secondary {
  color: #fff;
  background-color: #7ebca7;
  position: relative;
}
.cta-info .link-wrap .link-secondary::after {
  content: "";
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background-color: #38a37c;
}
@media only screen and (max-width: 767px) {
  .cta-info .link-wrap .link-secondary::after {
    height: 6px;
    bottom: -6px;
  }
}
.cta-info .link-wrap .link-secondary .link-inner {
  position: relative;
}
.cta-info .link-wrap .link-secondary .link-inner::before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: -2.8em;
  transform: translateY(-50%);
  background: center/contain no-repeat url(../image/common/icon_coupon.svg);
}

.p-pagenav {
  margin-top: 125px;
  margin-bottom: 95px;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .p-pagenav {
    width: 100%;
    order: 2;
  }
}
@media only screen and (max-width: 767px) {
  .p-pagenav {
    margin-top: 65px;
    margin-bottom: 80px;
  }
}

.p-pagenav .page-numbers {
  width: 55px;
  height: 55px;
  font-family: "Cormorant", serif;
  font-size: 45px;
  color: #262626;
  background-color: #d1d1d1;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  transition: 0.3s;
}
@media only screen and (max-width: 767px) {
  .p-pagenav .page-numbers {
    width: 40px;
    height: 40px;
    font-size: 33px;
  }
}

.p-pagenav .page-numbers:hover {
  background-color: #d1d1d1;
}

.p-pagenav .page-numbers:not(:first-child) {
  margin-left: 5px;
}

.p-pagenav .page-numbers.current {
  color: #fff;
  background-color: #262626;
}

.p-pagenav .page-numbers.dots {
  border: none;
}

.p-pagenav .page-numbers.prev,
.p-pagenav .page-numbers.next {
  background-color: #262626;
  position: relative;
}

.p-pagenav .page-numbers.prev::before,
.p-pagenav .page-numbers.next::before {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
}
@media only screen and (max-width: 767px) {
  .p-pagenav .page-numbers.prev::before,
.p-pagenav .page-numbers.next::before {
    width: 10px;
    height: 10px;
  }
}

.p-pagenav .page-numbers.prev::before {
  top: 23%;
  left: 43%;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform-origin: center;
  transform: rotate(-135deg) translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
  .p-pagenav .page-numbers.prev::before {
    top: 18%;
  }
}

.p-pagenav .page-numbers.next::before {
  top: 57%;
  left: 35%;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform-origin: center;
  transform: rotate(45deg) translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
  .p-pagenav .page-numbers.next::before {
    top: 55%;
    left: 33%;
  }
}

.js-scrollFadeAnim,
.js-staggerFadeItem {
  opacity: 0;
}